.dashboard-summary-card {
  display: flex;
  margin: 10px;
}

.dashboard-summary-average {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

.dashboard-summary-title {
  background-color: #f6f8fb;
  width: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
}

.dashboard-summary-gauge,
.dashboard-summary-time {
  width: 150px;
}

.dashboard-summary-gauge {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
}

.dashboard-summary-gauge text {
  font-weight: bold;
}

.dashboard-summary-time {
  position: relative;
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dashboard-summary-time-wrapper {
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-summary-time-wrapper b {
  font-size: 32px;
  padding-right: 3px;
  z-index: 1;
}

.dashboard-summary-time-wrapper span {
  margin-top: 10px;
}

.dashboard-summary-gauge p {
  margin-top: 0px;
}

.dashboard-graph-bg {
  position: absolute;
  height: 150px;
  bottom: 35px;
}
