.noDataWrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  gap: 20px;
}
.noDataWrapper img {
  width: 150px;
}

.circularProgress {
  position: relative;
  margin-top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
}
