.root-single {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.root-double {
  display: flex;
  align-items: center;
}

.undo-redo-wrapper {
  display: flex;
  align-items: center;
  padding-left: 25px;
}

@media (max-width: 1280px) {
  .undo-redo-wrapper {
    padding-left: 60px;
  }
}

.header-brand-campaign-info {
  margin-left: 10px;
}
.undo-redo-wrapper button {
  display: flex;
  padding: 5px 0px;
  max-width: 40px;
  max-height: 30px;
  min-width: 40px;
  min-height: 30px;
  border-color: lightgrey;
}

.undo-redo-wrapper button svg {
  width: 18px;
  color: grey;
}

.undo-redo-wrapper button svg:hover {
  color: #5569ff;
}

.button-undo {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.button-redo {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
