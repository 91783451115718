// IGNORE! - copied for making styling similar to Material - UI

.pureMaterialCheckbox {
  z-index: 0;
  position: relative;
  display: inline-block;
  color: white;
  line-height: 1.5;
  margin: 10px 0px;
}

/* Input */
.pureMaterialCheckbox > input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  z-index: -1;
  position: absolute;
  left: -10px;
  top: -8px;
  display: block;
  margin: 0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.6);
  box-shadow: none;
  outline: none;
  opacity: 0;
  transform: scale(1);
  pointer-events: none;
  transition: opacity 0.3s, transform 0.2s;
}

/* Span */
.pureMaterialCheckbox > span {
  display: inline-block;
  width: 100%;
  cursor: pointer;
}

/* Box */
.pureMaterialCheckbox > span::before {
  content: '';
  display: inline-block;
  box-sizing: border-box;
  margin: 3px 11px 3px 1px;
  border: solid 2px; /* Safari */
  border-color: white;
  border-radius: 2px;
  width: 18px;
  height: 18px;
  vertical-align: top;
  transition: border-color 0.2s, background-color 0.2s;
}

/* Checkmark */
.pureMaterialCheckbox > span::after {
  content: '';
  display: block;
  position: absolute;
  top: 3px;
  left: 1px;
  width: 10px;
  height: 5px;
  border: solid 2px transparent;
  border-right: none;
  border-top: none;
  transform: translate(3px, 4px) rotate(-45deg);
}

/* Checked, Indeterminate */
.pureMaterialCheckbox > input:checked,
.pureMaterialCheckbox > input:indeterminate {
  background-color: rgb(98, 0, 238);
}

.pureMaterialCheckbox > input:checked + span::before,
.pureMaterialCheckbox > input:indeterminate + span::before {
  border-color: rgb(98, 0, 238);
  background-color: rgb(98, 0, 238);
}

.pureMaterialCheckbox > input:checked + span::after,
.pureMaterialCheckbox > input:indeterminate + span::after {
  border-color: rgb(255, 255, 255);
}

.pureMaterialCheckbox > input:indeterminate + span::after {
  border-left: none;
  transform: translate(4px, 3px);
}

/* Hover, Focus */
.pureMaterialCheckbox:hover > input {
  opacity: 0.04;
}

.pureMaterialCheckbox > input:focus {
  opacity: 0.12;
}

.pureMaterialCheckbox:hover > input:focus {
  opacity: 0.16;
}

/* Active */
.pureMaterialCheckbox > input:active {
  opacity: 1;
  transform: scale(0);
  transition: transform 0s, opacity 0s;
}

.pureMaterialCheckbox > input:active + span::before {
  border-color: rgb(98, 0, 238);
}

.pureMaterialCheckbox > input:checked:active + span::before {
  border-color: transparent;
  background-color: rgba(0, 0, 0, 0.6);
}

/* Disabled */
.pureMaterialCheckbox > input:disabled {
  opacity: 0;
}

.pureMaterialCheckbox > input:disabled + span {
  color: rgba(0, 0, 0, 0.38);
  cursor: initial;
}

.pureMaterialCheckbox > input:disabled + span::before {
  border-color: currentColor;
}

.pureMaterialCheckbox > input:checked:disabled + span::before,
.pureMaterialCheckbox > input:indeterminate:disabled + span::before {
  border-color: transparent;
  background-color: currentColor;
}
