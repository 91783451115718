@import '../../styles/colors';
@import '../../styles/variables';

.sequenceBox {
  background: #f8f8f8;
  padding: 5px 5px 5px;
  border-radius: 10px;
  border: 1px solid #cfcfcf;
  height: 100%;
  position: relative;
}

.emptyBoxMessage {
  color: #888888;
  text-align: center;
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.5;
}

.newItemBTN {
  background-color: #fff;
  border: 1px solid #d3d3d3;
  border-radius: 6px;
  padding: 10px 25px;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 25px;

  &:hover {
    border-color: $primary-color;
  }

  &:active {
    background-color: $primary-color;
    color: #fff;
  }
}

.itemNameInput {
  border: none;
  padding-left: 3px;
  font-weight: normal;
  font-size: 14px;
  background-color: white;
  width: 100%;
  color: black;
}

.itemImage {
  margin-top: 2px;
  width: 54px;
  height: 54px;
  object-fit: cover;
  cursor: pointer;
  border-radius: unset;
}

.activeItem {
  position: absolute;
  background: #6ad86d !important;
  color: #fff;
  padding: 13px 10px 14px;
  top: -1px;
  border-radius: 0 10px 10px 0;
  right: -46px;
  font-size: 13px;
  animation: none !important;

  svg {
    display: none;
    font-size: 15px;
  }

  &:hover {
    right: -26px;

    svg {
      display: block;
    }

    span {
      display: none;
    }
  }

  &:active {
    background: #4dc751 !important;
  }
}

span > input {
  width: 100px;
}

.hideButton {
  display: none;
}

.rootImage {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

*::-webkit-scrollbar {
  background-color: #8f838341;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
*::-webkit-scrollbar-track {
  background-color: #f8f8f8;
}

/* scrollbar itself */
*::-webkit-scrollbar-thumb {
  background-color: #8f838341;
  border-radius: 16px;
  border: 4px solid #f8f8f8;
}

/* set button(top and bottom of the scrollbar) */
*::-webkit-scrollbar-button {
  display: none;
}
