.previewWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.previewTitle {
  text-align: center;
  margin: 15px 0;
}

.previewCard {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 15px 0;
  width: 100%;
}

.previewButton {
  border: none !important;
  width: 80%;
  margin: 20px 5px !important;
}

.previewLink {
  padding: 10px;
}

.sidebarBox {
  box-shadow: 0 3px 6px #00000029;
  border-radius: 10px;
}
