@import '../../../styles/colors';

.button {
  padding: 20px 65px;
  border: 2px solid $primary-color;
  background-color: #f5f5fc;
  color: $primary-color;
  text-align: center;
  border-radius: 10px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  margin: 5px;

  &:active {
    background-color: $primary-color;
    color: #fff;
  }
  &:hover {
    background-color: #e9e9f3;
  }
  &:disabled {
    color: #bfbfbf;
    border-color: #bfbfbf;
  }
}
