@import '../../styles/variables';
@import '../../styles/animations';

.sequenceDemo {
  background: #f8f8f8;
  padding: 5px 25px 5px;
  border-radius: 10px;
  border: 1px solid #cfcfcf;
  height: 100%;
  position: relative;
}

.sequenceDemoPreview {
  position: relative;
  width: 150px;
  height: 200px;
}

.mobileBox {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  color: black;
  @extend .center-center;
  &__header {
    background-color: #fff;
    img {
      width: 120px;
    }
  }

  &__images {
    height: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__title {
    text-align: center;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    // height: 125px;
    // max-height: 125px;
  }
  &__video__loader {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 85%;
  }
  &__video {
    padding: 25px;
    text-align: center;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 80%;
    // height: 125px;
    // max-height: 125px;
  }

  &__image {
    text-align: center;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 80%;
    // height: 125px;
    // max-height: 125px;
  }
  &__video {
    padding: 0px;
    text-align: center;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
    // height: 125px;
    // max-height: 125px;
  }

  .image {
    position: relative;
    height: 100% !important;
    background-color: #eaeaea;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      animation: fadeIn 0.5s ease 1;
    }
  }

  &.hasTitle {
    .image {
      height: 196px;
    }

    &.hasSixItems {
      .image {
        height: 130px;
      }
    }
  }

  &.hasVideoOrImage {
    .image {
      height: 185px;
    }

    &.hasSixItems {
      .image {
        height: 122px;
      }
    }
  }

  &.hasSixItems {
    .image {
      height: 160px;
    }
  }
}

.imageSingle {
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    animation: fadeIn 0.5s ease 1;
  }
}

.directLinkImage {
  cursor: pointer;
}

.directLinkWrapper {
  position: relative;
  background-color: black;
}

.directLinkLabel {
  font-family: 'Segoe UI';
  position: absolute;
  top: 50%;
  color: white;
  font-size: 25px;
  z-index: 500;
}

.fileInput {
  display: none;
}

.headingImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

//////////////////////
.splitS {
  height: 100%;
}

.splitHead {
  // height: calc(100% - 125px);
}

.splitOne,
.splitTwo,
.splitThree,
.splitFour,
.splitFive,
.splitSix {
  height: 100%;
  overflow: hidden;
}

.splitTwo {
  display: grid;
  gap: 0.5%;
  grid-template-rows: 100%;
  grid-template-columns: 50% 50%;
}

.splitThree {
  display: grid;
  gap: 0.5%;
  grid-template-rows: 100%;
  grid-template-columns: 33% 33% 33%;
}

.splitFour {
  display: grid;
  gap: 0.5%;
  grid-template-rows: 50% 50%;
  grid-template-columns: 50% 50%;
}

.splitFive {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 50% 50%;
  gap: 0.5%;
}

.splitFive div:nth-of-type(1) {
  grid-column-start: 1;
  grid-column-end: 4;
}

.splitFive div:nth-of-type(2) {
  grid-column-start: 4;
  grid-column-end: 7;
}

.splitFive div:nth-of-type(3) {
  grid-column-start: 1;
  grid-column-end: 3;
}

.splitFive div:nth-of-type(4) {
  grid-column-start: 3;
  grid-column-end: 5;
}

.splitFive div:nth-of-type(5) {
  grid-column-start: 5;
  grid-column-end: 7;
}

.splitSix {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 33% 33% 33%;
  gap: 0.5%;
}
